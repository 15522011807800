<template>
  <div>
    <v-container class="text-center mb-6 px-12" fluid>
      <Loader :loading="loading" />
      <div class="subtitle pa-4">
        <h2>{{ section == 3 ? "Resumen de " : "Nueva " }} solicitud de empleado</h2>
      </div>
      <v-row class="pa-0 my-6 text-left primary--text">
        <v-col cols="8" xs="12">
          <v-card>
            <hr />
            <v-row>
              <v-col cols="12" xs="12" class="pb-0">
                <div class="pa-4 pt-1">
                  <h2 class="pb-4 primary--text">Datos generales</h2>
                  <v-row>
                    <v-col cols="12" xs="12">
                      <v-autocomplete v-model="forms.new.empleado_solicita" :items="users" item-text="nombre_completo" item-value="idusuario" label="Empleado solicitante*" @change="userSchedule()" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="6" xs="12" class="py-0">
                <div class="pa-0 my-6 text-left primary--text">
                  <div class="pa-4 pt-1">
                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-autocomplete v-model="selectTypes" @change="avaibleSubtypes()" :items="types" item-text="nombre" item-value="idtipo_solicitud" label="Tipo de solicitud*" prepend-icon="mdi-calendar-week-begin" persistent-hint no-data-text="No se encontraron resultados" :disabled="!forms.new.empleado_solicita" hide-details dense outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="6" xs="12" class="py-0">
                <div class="pa-0 my-6 text-left primary--text">
                  <div class="pa-4 pt-1">
                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-autocomplete v-model="forms.new.subtipo_solicitud" @change="selectSubtype()" :items="subtypes" item-text="nombre" item-value="idsubtipo_solicitud" label="Subtipo de solicitud*" prepend-icon="mdi-calendar-week-begin" persistent-hint no-data-text="No se encontraron resultados" :disabled="!selectTypes" hide-details dense outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="pa-0 my-6 text-left primary--text">
              <div class="pa-4 pt-1">
                <v-row>
                  <v-col cols="12" xs="12">
                    <v-textarea v-model="forms.new.comentarios" label="Motivo" rows="1" prepend-icon="mdi-comment-outline" hide-details dense outlined></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="pa-0 my-6 primary--text text-center" v-if="changeSaturday">
              <div class="pa-4 pt-1">
                <v-row>
                  <v-col cols="12" md="6">
                    <p><span>Seleccionar el nuevo día de descanso</span></p>
                    <v-date-picker v-model="switchDates[0]" locale="es" no-title full-width></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p><span>Seleccionar el día de descanso para intercambiar</span></p>
                    <v-date-picker v-model="switchDates[1]" locale="es" no-title full-width></v-date-picker>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="pa-0 my-6 primary--text text-center" v-else-if="changeExtraTime">
              <div class="pa-4 pt-1">
                <v-row>
                  <v-col cols="12" md="6">
                    <p><span>Seleccionar los días con tiempo extra</span></p>
                    <v-date-picker v-model="switchDates[0]" locale="es" multiple no-title full-width :allowed-dates="allowedDatesPast"></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p><span>Seleccionar el día para intercambiar</span></p>
                    <v-date-picker v-model="switchDates[1]" locale="es" no-title full-width></v-date-picker>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="pa-0 my-6 text-left primary--text" v-else-if="!scheduleType">
              <div class="pa-4 pt-1">
                <h2 class="pb-4 primary--text">Fechas</h2>
                <v-row>
                  <v-col cols="4" xs="4">
                    <v-autocomplete v-model="typeSelectDates" :items="typeSelectDatesArray" label="Dias solicitados" prepend-icon="mdi-calendar" persistent-hint no-data-text="No se encontraron resultados" :disabled="!forms.new.subtipo_solicitud" hide-details dense outlined></v-autocomplete>
                  </v-col>
                  <v-col cols="4" xs="4">
                    <v-autocomplete v-model="schedule" :items="scheduleArray" label="Tiempo solicitado" prepend-icon="mdi-calendar" persistent-hint no-data-text="No se encontraron resultados" :disabled="!typeSelectDates" hide-details dense outlined></v-autocomplete>
                  </v-col>
                  <v-col cols="4" xs="4">
                    <v-text-field v-model.number="auxDays" label="Días" prepend-icon="mdi-calendar-clock" type="number" readonly dense outlined></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="pa-0 my-6 text-left primary--text" v-if="!scheduleType && !changeSaturday && typeSelectDates">
              <div class="pa-4 pt-1">
                <v-row>
                  <v-col cols="3" xs="3" v-if="typeSelectDates == 'Fechas especificas'"></v-col>
                  <v-col cols="6" xs="6" v-if="typeSelectDates == 'Fechas especificas'">
                    <v-date-picker v-model="dates" locale="es" multiple no-title full-width></v-date-picker>
                  </v-col>
                  <v-spacer v-if="typeSelectDates == 'Fechas especificas'"></v-spacer>
                  <v-col cols="6" xs="6" v-if="typeSelectDates == 'Días consecutivos'">
                    <Date-Picker v-model="begin" label="Fecha inicio" offYear hideDetails offLimit />
                  </v-col>
                  <v-col cols="6" xs="6" v-if="typeSelectDates == 'Días consecutivos'">
                    <Date-Picker v-model="end" label="Fecha fin" offYear hideDetails offLimit />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" xs="12">
          <v-card>
            <hr />
            <v-row>
              <v-col cols="12">
                <div class="pa-0 text-left primary--text">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Aprobadores</h2>
                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-data-table :headers="headersFlow" :items="flowSubtype" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
                          <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
                          <template v-slot:no-data> No hay información disponible </template>
                          <template v-slot:no-results> No se obtuvieron resultados </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="pa-0 my-6 text-left primary--text" v-if="vacation">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Detalle de vacaciones</h2>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="primary" small @click="downloadVacationReport()">
                          Reporte Individual
                          <v-icon right>mdi-file-download</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-simple-table>
                            <tbody>
                              <tr>
                                <td class="primary--text">Años de antigûedad</td>
                                <td>{{ detailVacation.antiguedad }}</td>
                              </tr>
                              <tr>
                                <td class="primary--text">Saldo de días</td>
                                <td>{{ detailVacation.saldo }}</td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header class="primary--text">Movimientos</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-simple-table>
                                  <thead>
                                    <tr>
                                      <th>Fecha inicio</th>
                                      <th>Fecha fin</th>
                                      <th>Días</th>
                                      <th>Origen</th>
                                      <th>Tipo</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(item, idx) in detailVacation.movimientos">
                                      <tr>
                                        <td>{{ item.fecha_inicio }}</td>
                                        <td>{{ (item.fecha_fin) ? item.fecha_fin : 'N/A' }}</td>
                                        <td>{{ item.dias }}</td>
                                        <td>{{ item.origen }}</td>
                                        <td>{{ item.tipo }}</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </v-simple-table>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="pa-0 my-6 text-left primary--text" v-if="changeExtraTime">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Establecer las horas para intercambiar</h2>
                    <v-row v-if="Object.keys(hoursExtraTime).length">
                      <v-col cols="4" v-for="(item, index) in hoursExtraTime" :key="index">
                        <v-text-field v-model.number="hoursExtraTime[index]" :label="index" :rules="rules.hora" :placeholder="getLabelExtraTime(index)" dense outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="Object.keys(hoursExtraTime).length">
                      <v-col cols="6">
                        <p class="my-0 mt-3">Hora de inicio Tiempo Extra</p>
                      </v-col>
                      <v-col cols="6">
                        <Hour-Picker v-model="forms.new.hora_inicio" label="Hora" hideDetails />
                      </v-col>
                    </v-row>
                    <v-row v-else justify="center">
                      <p class="my-0 mt-3">No se han seleccionado días con tiempo extra</p>
                    </v-row>
                  </div>
                </div>
                <div class="pa-0 my-6 text-left primary--text" v-if="scheduleType">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Seleccione el nuevo horario</h2>
                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-autocomplete v-model="forms.new.tipo_horario" :items="schedulesTypes" item-text="nombre" item-value="idtipo_horario" label="Tipo de horario" prepend-icon="mdi-calendar-blank-multiple" persistent-hint no-data-text="No se encontraron resultados" dense outlined> </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="pa-0 my-6 text-left primary--text" v-if="scheduleType">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Nuevo horario</h2>
                    <v-row>
                      <v-col cols="12" xs="12" v-if="forms.new.tipo_horario">
                        <Schedule-Table :scheduleData="searchSchedule(forms.new.tipo_horario)" :key="forms.new.tipo_horario" />
                      </v-col>
                      <h3 v-else class="pl-4">Seleccione un nuevo horario</h3>
                    </v-row>
                  </div>
                </div>
                <div class="pa-0 my-6 text-left primary--text">
                  <div class="pa-4 pt-1">
                    <h2 class="pb-4">Horario del empleado</h2>
                    <v-row>
                      <v-col cols="12" xs="12" v-if="scheduleUserInfo.length > 0">
                        <Schedule-Table :scheduleData="scheduleUserInfo" :key="scheduleUserInfo.length" />
                      </v-col>
                      <v-col cols="12" xs="12" v-else class="text-center">
                        <lottie-animation ref="anim" :animationData="require('@/assets/animations/search.json')" :loop="true" :autoPlay="true" style="width: 100%; height: 200px" />
                        <h2 class="primary--text">Información no disponible</h2>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="section != 1">
        <v-card class="pa-0 my-6 text-left primary--text" v-if="selectDates.length > 0">
          <div class="pa-4 pt-1">
            <div class="mb-4">
              <h2 class="pb-0">Configuración global</h2>
              <h3 class="instruction pb-4">En este apartado ingrese los datos globales que se reflejaran en los campos seleccionados</h3>
            </div>
            <v-row>
              <v-col cols="6" xs="12">
                <Hour-Picker v-model="global.begin_hour" label="Hora de inicio" hideDetails />
              </v-col>
              <v-col cols="6" xs="12">
                <Hour-Picker v-model="global.end_hour" label="Hora fin" hideDetails />
              </v-col>
              <v-col cols="12" xs="12">
                <v-textarea v-model="global.comment" label="Comentario" rows="1" prepend-icon="mdi-comment-outline" hide-details dense outlined></v-textarea>
              </v-col>
            </v-row>
            <div class="text-right mt-2">
              <Basic-Btn text="Actualizar elementos" color="primary" @click="updateSelect()" :loading="loading" />
            </div>
          </div>
        </v-card>
        <div v-for="(day, i) in selectDates" :key="i">
          <v-card class="pa-0 my-6 text-left">
            <div class="pa-4 pt-1">
              <v-checkbox hide-details class="mb-3" v-model="selectDates[i].active" :key="selectDates[i].active">
                <template v-slot:label>
                  <h2 class="primary--text">Ausencia: {{ selectDates[i].fecha }}</h2>
                </template>
              </v-checkbox>
              <v-row>
                <v-col cols="6" xs="12">
                  <Hour-Picker v-model="selectDates[i].hora_inicio" label="Hora de inicio" :key="selectDates[i].hora_inicio" hideDetails></Hour-Picker>
                </v-col>
                <v-col cols="6" xs="12">
                  <Hour-Picker v-model="selectDates[i].hora_fin" label="Hora fin" :key="selectDates[i].hora_fin" hideDetails></Hour-Picker>
                </v-col>
                <v-col cols="12" xs="12">
                  <v-textarea v-model="selectDates[i].comentario" label="Comentario" rows="1" prepend-icon="mdi-comment-outline" :key="selectDates[i].comentario" hide-details dense outlined></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
        <div v-if="selectDates.length == 0">
          <lottie-animation ref="anim" :animationData="require('@/assets/animations/noData.json')" :loop="true" :autoPlay="true" style="width: 100%; height: 300px" />
          <h1 class="primary--text">Seleccione días de ausencia</h1>
        </div>
      </div>
      <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar la solicitud?">
        <template v-slot:buttons>
          <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
          <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
        </template>
      </Delete-Message>
      <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
    </v-container>
    <div class="footer">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="6" xs="12">
          <Basic-Btn text="Guardar" color="success" @click="save()" :loading="loading" block xLarge />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>
  </div>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, HourPicker, ScheduleTable, DatePicker } from "@/components";
import moment from "moment";
import _ from "lodash";
import LottieAnimation from "lottie-web-vue";
import downloadjs from 'downloadjs'

export default {
  name: "new-employee-request",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    LottieAnimation,
    HourPicker,
    ScheduleTable,
    DatePicker,
    HourPicker
  },
  data: () => ({
    loading: false,
    scheduleType: false,
    changeSaturday: false,
    changeExtraTime: false,
    vacation: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    dates: [],
    switchDates: [
      null,
      null
    ],
    hoursExtraTime: {},
    realExtraTime: {},
    detailVacation: {},
    menu: false,
    search: "",
    detectChange: 0,
    section: 1,
    typeSelectDates: "",
    schedule: "",
    begin: "",
    end: "",
    scheduleArray: ["Medio dia", "Dia completo"],
    // typeSelectDatesArray: ["Días consecutivos", "Fechas especificas"],
    typeSelectDatesArray: ["Fechas especificas"],
    previewUserFlow: {},
    allSubtypes: [],
    scheduleUserInfo: [],
    previewFlow: [],
    active: [],
    open: [],
    flowSubtype: [],
    expandedReference: [],
    schedulesTypes: [],
    headersFlow: [
      { text: "Orden", align: "left", sortable: true, value: "orden" },
      { text: "Encargado", align: "left", sortable: false, value: "nombre_completo" },
      { text: "Correo de encargado", align: "left", sortable: false, value: "email" },
    ],
    types: [],
    subtypes: [],
    selected: [],
    selectDates: [],
    users: [],
    selectTypes: "",
    deleted: null,
    auxDays: 0,
    forms: {
      new: {
        subtipo_solicitud: 0,
        empleado_solicita: 0,
        comentarios: "",
        detalle_solicitud_empleado: [],
        dias: 0,
        hora_inicio: ''
      },
    },
    global: {
      begin_hour: "",
      end_hour: "",
      comment: "",
    },
    rules: {
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
      hora: [
        v => !!v || '',
        v => (/^\d(\.5)?$/.test(v)) || 'El campo es inválido',
        v => (/^\d(\.5)?$/.test(v) && parseFloat(v) >= 0.5) || 'El mínimo de horas es 0.5',
        v => (/^\d(\.5)?$/.test(v) && parseFloat(v) <= 8) || 'El máximo de horas son 8',
      ]
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  mounted() {
    this.getSchedule();
    this.refresh();
    this.getUser();
  },
  watch: {
    active(state) {
      this.previewUserFlow = this.previewFlow[state - 1];
    },
    begin() {
      this.dateRange();
    },
    end() {
      this.dateRange();
    },
    dates() {
      let newDates = [];
      let auxDates = [];
      for (let i = 0; i < this.dates.length; i++) {
        let day = moment(this.dates[i]).day()
        let idx = 0
        for (let j = 0; j < this.scheduleUserInfo.length; j++) {
          if (this.scheduleUserInfo[j].dia == day) {
            idx = j
          }
        }
        auxDates.push({
          fecha: this.dates[i],
          hora_inicio: this.scheduleUserInfo[idx].hora_inicio,
          hora_fin: this.scheduleUserInfo[idx].hora_fin,
          comentario: "",
          active: true,
          new: true,
        });
      }
      const that = this;
      _.each(auxDates, function (objeto) {
        let objeto2 = _.find(that.selectDates, function (o) {
          return o.fecha == objeto.fecha;
        });
        if (objeto2 ? objeto2.fecha === objeto.fecha : false) {
          let day = moment(objeto2.fecha).day()
          let idx = 0
          for (let j = 0; j < that.scheduleUserInfo.length; j++) {
            if (that.scheduleUserInfo[j].dia == day) {
              idx = j
            }
          }
          newDates.push({
            fecha: objeto2.fecha,
            hora_inicio: that.scheduleUserInfo[idx].hora_inicio,
            hora_fin: that.scheduleUserInfo[idx].hora_fin,
            comentario: objeto2.comentario,
            active: objeto2.active,
          });
        } else if (objeto.new) {
          let day = moment(objeto.fecha).day()
          let idx = 0
          for (let j = 0; j < that.scheduleUserInfo.length; j++) {
            if (that.scheduleUserInfo[j].dia == day) {
              idx = j
            }
          }
          newDates.push({
            fecha: objeto.fecha,
            hora_inicio: that.scheduleUserInfo[idx].hora_inicio,
            hora_fin: that.scheduleUserInfo[idx].hora_fin,
            comentario: objeto.comentario,
            active: objeto.active,
          });
        }
      });
      this.selectDates = newDates;

      this.selectDates = _.sortBy(this.selectDates, function (o) {
        return o.fecha;
      });
    },
    schedule() {
      this.forms.new.dias = this.selectDates.length
      if (this.schedule === 'Medio dia') {
        this.auxDays = this.forms.new.dias / 2
      }
    },
    selectDates() {
      this.forms.new.dias = this.selectDates.length
      if (this.schedule === 'Medio dia') {
        this.auxDays = this.forms.new.dias / 2
      }
      /*let auxId = this.forms.new.subtipo_solicitud
      let aux = _.filter(this.subtypes, function (o) {
        return auxId == o.idsubtipo_solicitud
      })
      if (aux[0].nombre !== 'VACACIONES') {
        this.forms.new.dias = this.selectDates.length
      }
      else {
        this.forms.new.dias = 0
      }*/
    },
    switchDates() {
      if (_.isArray(this.switchDates[0])) {
        for (let key in this.hoursExtraTime) {
          if (this.switchDates[0].indexOf(key) < 0) {
            delete this.hoursExtraTime[key]
            if (_.has(this.realExtraTime, key)) {
              delete this.realExtraTime[key]
            }
          }
        }
        for (let fecha of this.switchDates[0]) {
          if (!_.has(this.hoursExtraTime, fecha)) {
            this.hoursExtraTime[fecha] = null
          }
        }
        if (this.switchDates[0].length) {
          this.getExtraTime()
        }
      }
      else {
        this.hoursExtraTime = {}
        this.realExtraTime = {}
      }
    }
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.requestType)
        .then((response) => {
          this.types = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.getrSubtypes();
          this.loading = false;
        });
    },
    async getrSubtypes() {
      this.loading = true;
      this.axios
        .get(services.routes.requestSubtype + "/requestable")
        .then((response) => {
          this.allSubtypes = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombre_completo =
              (this.users[i].detalles_usuarios[0].no_empleado ? " " + this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSelect() {
      let count = 0;
      for (let i = 0; i < this.selectDates.length; i++) {
        if (this.selectDates[i].active) {
          this.selectDates[i].hora_inicio = this.global.begin_hour;
          this.selectDates[i].hora_fin = this.global.end_hour;
          this.selectDates[i].comentario = this.global.comment;
          count += 1;
        }
      }
      this.alert.active = true;
      this.alert.color = "success";
      this.alert.msg = "Elementos modificados " + count;
    },
    getDetailVacation() {
      this.loading = true;
      let params = {
        usuario: this.forms.new.empleado_solicita
      }
      this.axios
        .get(services.routes.employeeRequest + "/vacation", { params })
        .then((response) => {
          this.detailVacation = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectSubtype() {
      let auxId = this.forms.new.subtipo_solicitud;
      let aux = _.filter(this.subtypes, function (o) {
        return auxId == o.idsubtipo_solicitud;
      });
      if (aux[0].nombre == "VACACIONES") {
        this.vacation = true;
        this.scheduleType = false;
        this.changeSaturday = false;
        this.changeExtraTime = false;
        this.detailVacation = {}
        this.getDetailVacation()
      } else if (aux[0].nombre == "CAMBIO DE HORARIO") {
        this.scheduleType = true;
        this.vacation = false;
        this.changeSaturday = false;
        this.changeExtraTime = false;
      } else if (aux[0].nombre == "CAMBIO TIEMPO EXTRA") {
        this.changeExtraTime = true;
        this.vacation = false;
        this.changeSaturday = false;
        this.scheduleType = false;
      } else if (aux[0].nombre == "CAMBIO POR DESCANSO SÁBADO") {
        this.changeSaturday = true;
        this.vacation = false;
        this.scheduleType = false;
        this.changeExtraTime = false;
      } else {
        this.vacation = false;
        this.scheduleType = false;
        this.changeSaturday = false;
        this.changeExtraTime = false;
      }
      this.switchDates = [null, null]
      this.hoursExtraTime = {}
      this.realExtraTime = {}
      this.flowSubtype = aux[0].flujo_autorizaciones.data.flujo_aprobacion;
    },
    avaibleSubtypes() {
      let auxId = this.selectTypes;
      let aux = _.filter(this.allSubtypes, function (o) {
        return auxId == o.tipo_solicitud.idtipo_solicitud;
      });
      if (aux.length > 0) {
        this.subtypes = aux;
      }
    },
    save() {
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.forms.new));
      data.detalle_solicitud_empleado = this.sendDates();
      let days = [];
      for (let dia of this.scheduleUserInfo) {
        days.push(dia.dia)
      }
      for (let i = 0; i < data.detalle_solicitud_empleado.length; i++) {
        const fecha = moment(data.detalle_solicitud_empleado[i].fecha_inicio).local().format('YYYY-MM-DD')
        const dia = moment(data.detalle_solicitud_empleado[i].fecha_inicio).local().day()
        if (days.indexOf(dia) < 0) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `El empleado no cuenta con un horario asignado en la fecha: ${fecha}`
          return false
        }
      }
      if (this.changeSaturday) {
        data.fechas_cambio = [
          this.switchDates[0],
          this.switchDates[1]
        ]
      }
      if (this.changeExtraTime) {
        let sum = 0
        for (let key in this.hoursExtraTime) {
          let val = this.hoursExtraTime[key]
          let max = (this.realExtraTime[key]) ? this.realExtraTime[key] : 0
          max = (max > 0) ? max : 0
          max = max / 60
          max = this.formatNumber(max.toString())
          max = parseFloat(max)
          if (!(/^\d(\.5)?$/.test(val))) {
            this.loading = false
            this.alert.active = true
            this.alert.color = "error"
            this.alert.msg = `El formato de horas es inválido en la fecha: ${key}`
            return false
          }
          else if (!val) {
            this.loading = false
            this.alert.active = true
            this.alert.color = "error"
            this.alert.msg = `No se han establecido horas extras en la fecha: ${key}`
            return false
          }
          else if (val > 8) {
            this.loading = false
            this.alert.active = true
            this.alert.color = "error"
            this.alert.msg = `Ha excedio las 8 horas en la fecha: ${key}`
            return false
          }
          else if (val > max) {
            this.loading = false
            this.alert.active = true
            this.alert.color = "error"
            this.alert.msg = `Ha excedio el máximo de horas disponibles en la fecha: ${key}`
            return false
          }
          sum += parseFloat(val)
        }
        if (!this.switchDates[1]) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `No se ha seleccionado el día para intercambiar`
          return false
        }
        const dia = moment(this.switchDates[1]).local().day()
        if (days.indexOf(dia) < 0) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `El empleado no cuenta con un horario asignado en la fecha: ${this.switchDates[1]}`
          return false
        }
        else if (dia == 6 && sum != 1 && sum != 2 && sum != 3 && sum != 4) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `Se requieren de 1-4 horas para la fecha de intercambio: ${this.switchDates[1]}`
          return false
        }
        /*else if (dia != 6 && sum != 1 && sum != 2 && sum != 3 && sum != 4 && sum != 8) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `Se requieren de 1-4 horas u 8 horas para la fecha de intercambio: ${this.switchDates[1]}`
          return false
        }*/
        // Se habilitan temporalmente de 1-8 horas
        else if (dia != 6 && sum != 1 && sum != 2 && sum != 3 && sum != 4 && sum != 5 && sum != 6 && sum != 7 && sum != 8) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `Se requieren de 1-4 horas u 8 horas para la fecha de intercambio: ${this.switchDates[1]}`
          return false
        }
        data.fechas_cambio = [
          this.switchDates[0],
          this.switchDates[1]
        ]
        data.horas_extra = this.hoursExtraTime
        if (!data.hora_inicio) {
          this.loading = false
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = `La hora de inicio de tiempo extra no ha sido seleccionada`
          return false
        }
        let _hora_inicio = null;
        let _hora_fin = null;
        for (let day of this.scheduleUserInfo) {
          if (day.dia === dia) {
            _hora_inicio = day.hora_inicio;
            _hora_fin = day.hora_fin;
            if (_hora_inicio && _hora_fin) {
              const fecha_actual = moment().format('YYYY-MM-DD');
              const _diff_hora_inicio = moment(fecha_actual + ' ' + _hora_inicio).diff(moment(fecha_actual + ' ' + data.hora_inicio), 'minutes');
              const _diff_hora_fin = moment(fecha_actual + ' ' + _hora_fin).diff(moment(fecha_actual + ' ' + data.hora_inicio), 'minutes');
              if (_diff_hora_inicio > 0 || _diff_hora_fin < 0) {
                this.loading = false
                this.alert.active = true
                this.alert.color = "error"
                this.alert.msg = `La hora de inicio de tiempo extra no se encuentra dentro del horario asignado`
                return false
              }
            }
          }
        }
      }
      if (!this.scheduleType && !this.changeSaturday && !this.changeExtraTime && this.schedule == '') {
        this.loading = false
        this.alert.active = true
        this.alert.color = "error"
        this.alert.msg = "Debe seleccionar el Tiempo solicitado"
        return false
      }
      this.axios
        .post(services.routes.employeeRequest + "/new", { data })
        .then((response) => {
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Solicitud creada exitosamente";
          this.$router.push({
            path: "/solicitud-empleados",
          });
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getSchedule() {
      this.loading = true;
      this.axios
        .get(services.routes.scheduleType)
        .then((response) => {
          this.schedulesTypes = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    sendDates() {
      let send = [];
      for (let info of this.selectDates) {
        const day = moment(info.fecha).day()
        let begin_date = moment(info.fecha + " " + info.hora_inicio).format();
        let end_date = moment(info.fecha + " " + info.hora_fin).format();
        if (this.schedule == "Medio dia") {
          end_date = (day != 6) ? moment(begin_date).add(4, "h") : moment(begin_date).add(2, "h")
        }
        begin_date = moment(begin_date).utc().format();
        end_date = moment(end_date).utc().format();
        send.push({
          fecha_inicio: begin_date,
          fecha_fin: end_date,
          comentarios: "",
        });
      }
      return send;
    },
    searchSchedule(id) {
      let send = [];
      for (let schedule of this.schedulesTypes) {
        if (schedule.idtipo_horario == id) {
          send = schedule.detalles_tipos_horarios;
        }
      }
      return send;
    },
    dateRange() {
      if (this.begin && this.end) {
        this.selectDates = [];
        for (let m = moment(this.begin); m.diff(this.end, "days") <= 0; m.add(1, "days")) {
          let day = moment(m.format("YYYY-MM-DD")).day()
          let idx = 0
          for (let j = 0; j < this.scheduleUserInfo.length; j++) {
            if (this.scheduleUserInfo[j].dia == day) {
              idx = j
            }
          }
          this.selectDates.push({
            fecha: m.format("YYYY-MM-DD"),
            hora_inicio: this.scheduleUserInfo[idx].hora_inicio,
            hora_fin: this.scheduleUserInfo[idx].hora_fin,
            comentario: "",
          });
        }
      }
    },
    userSchedule() {
      const that = this;
      let employee = _.find(that.users, function (o) {
        return o.idusuario == that.forms.new.empleado_solicita;
      });
      this.scheduleUserInfo = employee.detalles_usuarios[0].tipo_horario.detalles_tipos_horarios;
      this.switchDates = [null, null]
      this.hoursExtraTime = {}
      this.realExtraTime = {}
      if (this.vacation) {
        this.getDetailVacation()
      }
    },
    allowedDates(val) {
      return [5].includes(new Date(val).getDay()) && (new Date(val) >= new Date());
    },
    allowedDatesPast(val) {
      return (moment(val).local() <= moment().local());
    },
    allowedDatesFuture(val) {
      return (moment(val).local() > moment().local());
    },
    allowedDatesTodayAndFuture(val) {
      return (moment(val).local() >= moment().local().startOf('day'));
    },
    getExtraTime() {
      this.loading = true
      let params = {
        usuario: this.forms.new.empleado_solicita,
        fechas: this.switchDates[0]
      }
      this.axios.get(services.routes.employeeRequest + '/extraTime', { params })
        .then((response) => {
          this.realExtraTime = response.data.data
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatNumber(x) {
      let arr = x.split('.')
      if (arr.length == 2) {
        return (parseInt(arr[1].split('')[0]) >= 5) ? parseFloat(arr[0] + '.5') : parseFloat(arr[0] + '.0')
      }
      return x
    },
    getLabelExtraTime(date) {
      if (date && this.realExtraTime[date] && this.realExtraTime[date] > 0) {
        let hours = this.realExtraTime[date] / 60
        let str = this.formatNumber(hours.toString()).toString() + ' horas extras'
        if (hours == 1) {
          str = hours.toString() + ' hora extra'
        }
        else if (hours == 0) {
          str = 'Sin horas extras'
        }
        return str
      }
      return 'Sin horas extras'
    },
    downloadVacationReport() {
      this.loading = true
      this.axios({
        url: services.routes.employeeRequest + '/vacation/report',
        method: 'POST',
        data: { 
          data: {
            usuario: this.forms.new.empleado_solicita
          }
         },
        responseType: 'blob'
      })
      .then((response) => {
        const filename = 'Reporte Individual de Vacaciones'
        downloadjs(response.data, filename + '.pdf', "application/pdf");
      })
      .catch((error) => {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "El reporte no puede ser generado";
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped>
  hr {
    border-left: 8px solid #6c1414;
    height: 100%;
    position: absolute;
    border-color: none;
  }
  .instruction {
    font-size: 16px;
  }
</style>
